/**
* Generated automatically at built-time (2024-11-11T12:27:39.763Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "komfyr-black-friday",templateKey: "sites/94-d41588f4-3586-4b86-9d72-bbe5d6fc1ba8"};